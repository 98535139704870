@import "./bootstrap_grid";

$red: #f45b69;
$black: #000;
$rainbow: linear-gradient(
  124deg,
  #ff2400,
  #e81d1d,
  #e8b71d,
  #e3e81d,
  #1de840,
  #1ddde8,
  #2b1de8,
  #dd00f3,
  #dd00f3
);

html,
body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  height: 100%;
}

#root {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
}

.loader {
  .logo {
    width: 30px;
    height: 30px;
    margin: 0 auto;
  }
}

h1 {
  font-size: 60px;
  line-height: 1.3;
}

button,
a {
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  padding: 10px 15px;
  font-weight: 400;

  text-decoration: none;
  &.is-red {
    background-color: $red;
    color: #fff;
    &:focus {
      border-radius: 4px;
      outline: $red solid 1px;
    }
  }
}

.is-block {
  display: block;
}

.text-center {
  text-align: center;
}

.is-centered {
  justify-content: center;
  align-items: center;
}

.is-paddingless {
  padding: 0 !important;
}

.is-60 {
  width: 60%;
}

.is-50 {
  width: 50%;
}

.header {
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;

  p {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.row {
  padding: 0 15px;
  display: flex;
}

.text-right {
  text-align: right;
}

.text-column {
  z-index: 2;
  position: relative;
  margin-left: 60px;
}

.background-image {
  height: 100vh;
  opacity: 1;
  background: {
    size: cover;
    position: center;
    repeat: no-repeat;
  }

  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  top: 0;
  width: 40%;
}

input {
  padding: 10px 7px;

  &.is-black {
    display: block;
    width: 100%;
    color: $red;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid lighten($color: $red, $amount: 15);
    margin-bottom: 20px;
    &:focus {
      outline: none;
    }
  }
}

.section {
  height: 100vh;
  border-bottom: 2px solid #ccc;

  &--about {
    .container-fluid {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.footer {
  padding: 15px 0;
  background-color: #e5e5e5;
  color: black;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Poppins", serif;
  span {
    font-size: 12px;
    line-height: 1;
    color: black;
    a {
      padding: 0;
      font-size: 12px;
      line-height: 1;
      color: black;
      text-decoration: underline;
    }
  }
}

.subscribe-page {
  position: relative;
  background-color: white;
  padding: 60px 0;

  h1 {
    font-size: 40px;
    line-height: 1.4;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.form {
  margin-top: 0px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
  p {
    text-align: left;

    &.team-message {
      margin-bottom: 60px;
    }
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0 0 20px;
    text-align: left;
  }

  input,
  select {
    border: none;
    border-bottom: 3px solid black;
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    background-color: white;
    color: black;
    width: 100%;
    font-family: "Poppins", serif;
    &:focus {
      outline: none;
    }
  }

  button {
    margin-top: 10px;
    background-color: black;
    overflow: hidden;
    margin-bottom: 30px;
    color: white;
  }

  label {
    font-family: "Poppins", serif;
    color: lighten($color: #000000, $amount: 40);
    font-size: 12px;
    padding-left: 3px;
  }

  select {
    margin-top: 20px;

    option {
      padding-left: 7px;
    }
  }
}

.back {
  position: fixed;
  top: 10px;
  left: 30px;
  color: black;
  font-weight: 500;
  z-index: 99;
  padding: 0;
}

.message {
  position: fixed;
  bottom: 0;
  right: 0px;
  padding: 10px;
  color: white;
  text-align: center;
  z-index: 2;

  &.error {
    background-color: $red;
  }

  &.success {
    background-color: #d4edda;
    color: black;
  }

  &.sending {
    background-color: black;
  }
}

.flag {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  width: 20px;
  display: block;
  z-index: 99;
}

.title {
  position: fixed;
  top: 10px;
  left: 30px;
  color: #000;
  text-decoration: none;
  display: block;
  z-index: 9;
  padding: 0;
  font-weight: 500;
  span {
    vertical-align: middle;
    display: inline-block;
    margin-top: 15px;
  }

  .logo {
    vertical-align: top;
    display: inline-block;
    margin-right: 15px;
  }
}

.nav {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 99;
}

.dropdownMenu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 31px;
  z-index: -1;
  width: 50px;
  text-align: center;
  transform: translateY(-100%);
  transition: transform 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.dropdownLink {
  text-decoration: none;
  display: block;
  background-color: #222;
  color: #4a4a4f;
  padding: 2px 5px;
  transition: background-color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67),
    color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  font-size: 14px;
  border-radius: 0;
  width: 48px;

  &:focus {
    outline: none;
  }
}

.dropdownLink:hover {
  color: #fff;
  background-color: #a0a0a3;
}

.dropdown {
  position: relative;
  width: 48px;
  button {
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 7px;
    border-radius: 0;
    background-color: #222;
    color: #fff;
  }
}

.dropdown:hover .dropdownMenu {
  transform: translateY(0);
}

.form-message {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}

.form-description {
  padding: 5px 7px;
  margin: 0;
}

.validation-message {
  background-color: $red;
  margin: 0;
  color: white;
  border-radius: 5px;
  padding: 5px 7px; // same as .form-description
}

.hidden {
  display: none !important;
}

.is-red > div {
  border-color: $red;

  &:hover,
  &:focus {
    border-color: $red;
  }
}

.is-black > div {
  border-color: $black;

  &:hover,
  &:focus {
    border-color: $black;
  }
}

@media (max-width: 1199px) {
  .title {
    color: white;
  }
  .header {
    background-image: none;
  }
  .background-image {
    width: 100%;
    background-size: cover;
    background-position: center;
    .layer {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  .header {
    h1 {
      color: white;
    }

    p {
      color: white;
    }
  }
}

@media (min-width: 768px) {
  .text-right-lg {
    text-align: right;
  }

  .form-message {
    min-height: 50px;
  }
}

@media (max-width: 767px) {
  .flag {
    width: 10px;
  }
  .text-column {
    margin-left: 0px;
  }
  .header {
    h1 {
      font-size: 50px;
      line-height: 1.2;
    }

    p {
      font-size: 19px;
      margin-bottom: 20px;
    }

    a {
      font-size: 16px;
    }
  }
  .subscribe-page {
    h1 {
      max-width: none;
    }
  }

  .form-message {
    min-height: 80px;
  }
}

@media (max-width: 479px) {
  .header {
    h1 {
      font-size: 40px;
    }
  }
  .subscribe-page {
    h1 {
      font-size: 35px;
    }
    button {
      margin-bottom: 20px;
    }
  }
}

@media (max-height: 400px) {
  .header {
    h1 {
      font-size: 40px;
    }

    p {
      display: none;
    }
  }
}
